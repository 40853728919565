(function($) {

  if (!$('.calculator')) {
    return false;
  }

  function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  }

  var val = 0;
  var unit = "";

  var outputDenier, outputTex, outputCotton, outputWoollen, outputWorsted, outputMetric;
  var op = {
    denier: 0,
    tex: 0,
    cotton: 0,
    woollen: 0,
    worsted: 0,
    metric: 0,
  };

  function getUnits() {
    val = parseFloat($('.calculator input').val());
    unit = $('.calculator').find('select').val();
  }

  function recalculate() {

    getUnits();

    $('b.selected').removeClass('selected');

    if (isNaN(val) || !val) {
      op.denier = '0';
      op.tex = '0';
      op.metric = '0';
      op.cotton = '0';
      op.woollen = '0';
      op.worsted = '0';

    } else {
      switch (unit) {
        case 'denier':
          $('.heading--denier').addClass('selected');
          op.denier = val;
          op.tex = val / 9;
          op.metric = 9000 / val;
          op.cotton = 5319 / val;
          op.woollen = 17439 / val;
          op.worsted = 7972 / val;
          break;
        case 'tex':
          $('.heading--tex').addClass('selected');
          op.denier = val * 9;
          op.tex = val;
          op.metric = 1000 / 9;
          op.cotton = 591 / val;
          op.woollen = 1937.7 / val;
          op.worsted = 885.8 / val;
          break;
        case 'metric':
          $('.heading--metric').addClass('selected');
          op.denier = 9000 / val;
          op.tex = 1000 / val;
          op.metric = val;
          op.cotton = val / 1.693;
          op.woollen = val / 0.516;
          op.worsted = val / 1.129;
          break;
        case 'cotton':
          $('.heading--cotton').addClass('selected');
          op.denier = 5314.9 / val;
          op.tex = 591 / val;
          op.metric = val * 1.693;
          op.cotton = val;
          op.woollen = val * 3.28;
          op.worsted = val / 0.666;
          break;
        case 'woollen':
          $('.heading--woollen').addClass('selected');
          op.denier = 17439 / val;
          op.tex = 1937.7 / val;
          op.metric = val * 0.516;
          op.cotton = val/3.28;
          op.woollen = val;
          op.worsted = val / 2.187;
          break;
        case 'worsted':
          $('.heading--worsted').addClass('selected');
          op.denier = 7972 / val;
          op.tex = 885.8 / val;
          op.metric = val / 0.886;
          op.cotton = val / 1.5;
          op.woollen = val * 2.187;
          op.worsted = val;
          break;

      }
    }

    $('.output--denier').text(round(op.denier, 4));
    $('.output--tex').text(round(op.tex, 4));
    $('.output--cotton').text(round(op.cotton, 4));
    $('.output--woollen').text(round(op.woollen, 4));
    $('.output--worsted').text(round(op.worsted, 4));
    $('.output--metric').text(round(op.metric, 4));
  }

  $('.calculator').find('input, select').on('keydown keyup change', function(){
    recalculate();
  });

  recalculate();

})(jQuery); // Fully reference jQuery after this point.
