/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.woocommerce-ordering').prepend('<button class="js-showfilters">Filters</button>');
        $('.woof').prepend('<button class="js-closefilters">Close</button>');
        $('.js-showfilters, .js-closefilters').on('click', function(){
          $('body').toggleClass('mode--filters');
        });

        $('.woof_checkbox_authors_container li.woof_list').each(function(i, el) {
          $(el).html($(el).html().replace("&nbsp;&nbsp;", ""));
        });

        $('.product-accordion__title').on('click', function(){
          if ($(this).hasClass('product-accordion__title--active')) {
            $('.product-accordion__title').removeClass('product-accordion__title--active');
          } else {
            $('.product-accordion__title').removeClass('product-accordion__title--active');
            $(this).addClass('product-accordion__title--active');
          }
        });

        if ($('.widget_price_filter').children().length === 0) {
          $('.widget_price_filter').parents('.woof_price_search_container').hide();
        }

        // JavaScript to be fired on all pages
        $('.upsells ul.products, .products--slick').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1
        });

        $('.js-menuOpen').on('click', function(){
          $('body').addClass('mode--navigation');
        });

        $('.js-menuClose').on('click', function(){
          $('body').removeClass('mode--navigation');
        });

         $(window).scroll(function(){
          var wH = $('.header').height()/3,
              wS = $(window).scrollTop();
          if (wS > wH) {
            $('body').addClass('mode--scroll');
          } else {
            $('body').removeClass('mode--scroll');
          }
        });

        var slider = $('.slick').slick({
          infinite: true,
          speed: 300,
          slidesToShow: 1,
        });

        $('.slick-control div').on('click', function(){
          var index = $(this).index();
          $(slider).slick('slickGoTo', index);
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'journal': {
      init: function() {
        $('.journalFilter__link').on('click', function(){
          $.ajax({
            url: $(this).attr('href'),
            dataType: 'html',
            success: function(response) {

              var myTestDiv = document.createElement('div');
              var mystr = '<div id="main"></div>';
              myTestDiv.innerHTML = response;

              var results = $(myTestDiv).find('ul.pods__list');

              $('ul.pods__list').html(results);

            }
          });

          $('.journalFilter__link--active').removeClass('journalFilter__link--active');
          $(this).addClass('journalFilter__link--active');

          return false;


        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
